import React from 'react';
import { getHours, getDay } from 'date-fns';

import { useConfigContext } from '../../context/config';
import useInterval from '../../hooks/useInterval';
import Widget from '..';

import './schedule.scss';

const DELAY = 30000;

interface ScheduleEvent {
  title: string;
  icon: string;
  dayOfWeek: number;
  startHourOfDay: number;
  endHourOfDay: number;
}

const Schedule = () => {
  const [state, updateState] = React.useState<{
    events: ScheduleEvent[];
  }>({ events: [] });
  const { config } = useConfigContext();

  useInterval(() => {
    const filterEvents = () => {
      const now = new Date();
      const currentHour = getHours(now);
      const currentDay = getDay(now);

      const events = (
        (config && config.schedule && config.schedule.events) ||
        []
      ).filter(
        ({ dayOfWeek, startHourOfDay, endHourOfDay }: ScheduleEvent) =>
          currentDay === dayOfWeek &&
          currentHour >= Number(startHourOfDay) &&
          currentHour < Number(endHourOfDay)
      );

      updateState({ events });
    };

    filterEvents();
  }, DELAY);

  const { events } = state;
  if (events.length === 0) {
    return null;
  }

  return (
    <Widget widgetName="schedule">
      <ul>
        {events.map(({ title, icon }) => (
          <li key={title}>
            <i className={`fa fa-${icon}`} /> {title}
          </li>
        ))}
      </ul>
    </Widget>
  );
};

export default Schedule;
