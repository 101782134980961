import React, { useState } from 'react';

import { BikeMarket, marketSettings } from '../../widgets/Bikes/types';
import { useConfigContext } from '../../context/config';
import {
  Form,
  RadioGroup,
  TextField,
  InputList,
  Switch
} from './FormComponents';

interface BikeFormState {
  isEditing: boolean;
  editingField: string;
}

const initialConfig = {
  isActive: false,
  market: undefined,
  radius: undefined,
  stations: []
};

const BikeForm = () => {
  const [state, updateState] = useState<BikeFormState>({
    isEditing: false,
    editingField: ''
  });
  const { config, updateConfig } = useConfigContext();

  const bikeConfig = (config && config.bikes) || initialConfig;

  const editField = (editingField: string) => {
    updateState({ ...state, isEditing: true, editingField });
  };

  const closeAllFields = () => {
    updateState({ ...state, isEditing: false, editingField: '' });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateConfig({ ...bikeConfig, [e.target.name]: e.target.value }, 'bikes');
    closeAllFields();
  };

  const handleToggle = (fieldName: string, value: boolean) => {
    updateConfig({ ...bikeConfig, [fieldName]: value }, 'bikes');
    closeAllFields();
  };

  const onStationsUpdate = (stations: any[]) => {
    updateConfig({ ...bikeConfig, stations }, 'bikes');
    closeAllFields();
  };

  const { isActive, market, radius, stations } = bikeConfig;
  return (
    <Form>
      <Switch
        fieldName="isActive"
        label="Active"
        value={isActive}
        onToggle={handleToggle}
      />

      <RadioGroup
        fieldName="market"
        label="Bikeshare market"
        emptyText="You haven't specified a market."
        onEditOpen={editField}
        onEditClose={closeAllFields}
        onChange={handleChange}
        value={market}
        isEditing={state.editingField === 'market'}
        radioOptions={Object.values(BikeMarket).map(value => ({
          value
        }))}
      />

      <TextField
        fieldName="radius"
        inputType="number"
        inputProps={{ min: 0 }}
        label="Radius (in meters)"
        emptyText="You haven't specified a radius to monitor for undocked ebikes."
        onEditOpen={editField}
        onEditClose={closeAllFields}
        onChange={handleChange}
        value={radius}
        isEditing={state.editingField === 'radius'}
      />

      <InputList
        fieldName="stations"
        label="Stations"
        emptyText="No stations added."
        isEditing={state.editingField === 'stations'}
        onEditOpen={editField}
        onEditClose={closeAllFields}
        listItemFields={[
          {
            fieldName: 'stationId',
            label: 'Station id',
            style: { width: 200, marginRight: 5 }
          },
          {
            fieldName: 'stationName',
            label: 'Station name'
          }
        ]}
        onChange={onStationsUpdate}
        value={stations}
      />
    </Form>
  );
};

export default BikeForm;
