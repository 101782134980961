import React, { useState } from 'react';
import { fromUnixTime, differenceInMinutes } from 'date-fns';

import Widget from '..';
import { useConfigContext } from '../../context/config';
import { timeToLeaveString } from '../helpers';
import { fetchDepartures } from './helpers';
import useInterval from '../../hooks/useInterval';
import { Complex, Departure } from './types';

import './styles.scss';

const DELAY = 30000;

enum Direction {
  NORTH = 'NORTH',
  EAST = 'EAST',
  SOUTH = 'SOUTH',
  WEST = 'WEST'
}

interface StationConfig {
  complexId: string;
  minToWalk: number;
  direction: Direction;
}

interface MtaSubwayState {
  stations: Complex[];
}

interface DepartureWithTimeToLeave extends Departure {
  timeToLeave: number;
}

const MtaSubway = () => {
  const [state, updateState] = useState<MtaSubwayState>({
    stations: []
  });
  const { config } = useConfigContext();
  const configStations: StationConfig[] =
    (config && config.mtaSubway && config.mtaSubway.stations) || [];

  useInterval(() => {
    const getData = async () => {
      const complexIds = configStations.map((s: StationConfig) => s.complexId);
      if (complexIds.length === 0) return;

      const stations = await fetchDepartures(
        process.env.REACT_APP_MTA_API_KEY!,
        complexIds
      );
      updateState({ stations: stations || [] });
    };

    getData();
  }, DELAY);

  const { stations } = state;
  const now = new Date();

  const noTrains = stations
    .map(({ lines }) => lines.length === 0)
    .every(isEmpty => isEmpty);

  return (
    <Widget widgetName="subway" logo={<div className="logo" />} label="Subway">
      {noTrains
        ? 'No upcoming train departures.'
        : stations.map(
            ({
              complexId,
              stopName,
              northDirectionLabel,
              southDirectionLabel,
              lines
            }) => {
              const { direction, minToWalk } = configStations.find(
                (s: StationConfig) => s.complexId
              )!;
              const directionToLabelMap: {
                [key in StationConfig['direction']]?: string;
              } = {
                NORTH: northDirectionLabel,
                SOUTH: southDirectionLabel,
                EAST: southDirectionLabel,
                WEST: northDirectionLabel
              };

              if (lines.length === 0) return null;

              return (
                <div key={complexId}>
                  <b>
                    {stopName} to {directionToLabelMap[direction]}
                  </b>
                  {lines.map(({ name, departures }) => {
                    const departuresToShow = departures[direction].reduce(
                      (acc: DepartureWithTimeToLeave[], departure, i) => {
                        const departureTime = fromUnixTime(departure.time);
                        const numMin = differenceInMinutes(departureTime, now);
                        const timeToLeave = numMin - minToWalk;
                        if (timeToLeave < 0 || acc.length > 2) return acc;

                        return [...acc, { ...departure, timeToLeave }];
                      },
                      []
                    );

                    return (
                      <React.Fragment key={name}>
                        {departuresToShow.map(
                          ({ tripId, routeId, timeToLeave }) => {
                            return (
                              <div className="departure" key={tripId}>
                                <div className={`subway subway-${routeId}`}>
                                  {routeId}
                                </div>
                                <div className="time">
                                  {timeToLeaveString(timeToLeave)}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            }
          )}
    </Widget>
  );
};

export default MtaSubway;
