export interface PlaceDetail {
  formatted_address: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
}

export default function useGooglePlaces() {
  const { google } = window as any;
  const map = document.createElement('div');

  const placesService = { current: null };

  if (!placesService.current && google) {
    placesService.current = new google.maps.places.PlacesService(map);
  }

  const getPlaceDetails = (placeId: string) => {
    if (!placesService.current) {
      return undefined;
    }

    const request = {
      placeId,
      fields: ['name', 'geometry', 'formatted_address']
    };

    return new Promise((resolve, reject) => {
      (placesService as any).current.getDetails(
        request,
        (place: PlaceDetail, status: any) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK)
            reject(status);

          resolve(place as PlaceDetail);
        }
      );
    });
  };

  return { getPlaceDetails };
}
