import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import { useConfigContext } from '../../context/config';
import { Form, RadioGroup, InputList, Switch } from './FormComponents';

interface FormState {
  isEditing: boolean;
  editingField: string;
}

const initialConfig = {
  isActive: false,
  agency: undefined,
  stops: []
};

const NextBusForm = () => {
  const [state, updateState] = useState<FormState>({
    isEditing: false,
    editingField: ''
  });
  const { config, updateConfig } = useConfigContext();

  const nextBusConfig = (config && config.nextBus) || initialConfig;

  const editField = (editingField: string) => {
    updateState({ ...state, isEditing: true, editingField });
  };

  const closeAllFields = () => {
    updateState({ ...state, isEditing: false, editingField: '' });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateConfig(
      { ...nextBusConfig, [e.target.name]: e.target.value },
      'nextBus'
    );
    closeAllFields();
  };

  const handleToggle = (fieldName: string, value: boolean) => {
    updateConfig({ ...nextBusConfig, [fieldName]: value }, 'nextBus');
    closeAllFields();
  };

  const onStopsUpdate = (stops: any[]) => {
    updateConfig({ ...nextBusConfig, stops }, 'nextBus');
    closeAllFields();
  };

  const { isActive, agency, stops } = nextBusConfig;
  return (
    <Form>
      <Switch
        fieldName="isActive"
        label="Active"
        value={isActive}
        onToggle={handleToggle}
      />

      <RadioGroup
        fieldName="agency"
        label="Agency"
        emptyText="You haven't specified an agency."
        onEditOpen={editField}
        onEditClose={closeAllFields}
        onChange={handleChange}
        isEditing={state.editingField === 'agency'}
        // TODO load these from a list
        radioOptions={[
          { value: 'sf-muni', label: 'SF Muni' },
          { value: 'ucb', label: 'UC Berkeley' }
        ]}
        value={agency}
      />

      <InputList
        fieldName="stops"
        label="Stops"
        emptyText="No stops added."
        isEditing={state.editingField === 'stops'}
        onEditOpen={editField}
        onEditClose={closeAllFields}
        listItemFields={[
          {
            fieldName: 'stopId',
            label: 'Stop',
            style: { width: 200, marginRight: 5 }
          },
          {
            fieldName: 'route',
            label: 'Route tag',
            style: { width: 200, marginRight: 5 }
          },
          {
            fieldName: 'minToWalk',
            label: 'Walking time to stop (in mins)'
          }
        ]}
        onChange={onStopsUpdate}
        value={stops}
      />

      <Typography variant="caption">
        Find your route and stop info{' '}
        <a
          href={`http://www.nextbus.com/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        . You may need to open your browser's dev tab.
      </Typography>
    </Form>
  );
};

export default NextBusForm;
