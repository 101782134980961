import React, { useState } from 'react';
import cn from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';

import { FormListItemProps, Option } from './utils';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  margin: {
    margin: theme.spacing(1),
    marginTop: '16px',
    marginBottom: '8px'
  },
  textField: {
    marginRight: theme.spacing(1),
    flexGrow: 1
  },
  select: {
    marginLeft: 0
  }
}));

interface ListItemField {
  fieldName: string;
  label: string;
  style?: any;
  options?: Option[];
}

const ListItemInput = ({
  field,
  value,
  onChange
}: {
  field: ListItemField;
  value: Option['value'];
  onChange: (fieldName: string, value: string | number) => void;
}) => {
  const classes = useStyles();
  const { fieldName, label, style, options } = field;

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    onChange(fieldName, e.target.value as string | number);
  };

  if (options) {
    return (
      <FormControl
        variant="outlined"
        className={cn(classes.margin, classes.select)}
      >
        <Select value={value} onChange={handleChange} style={style}>
          <MenuItem>N/A</MenuItem>
          {options.map(o => (
            <MenuItem key={o.value} value={o.value}>
              {o.label || o.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <TextField
      name={fieldName}
      style={style}
      label={label}
      classes={{ root: classes.textField }}
      variant="outlined"
      margin="normal"
      onChange={handleChange}
      InputLabelProps={{ shrink: true }}
      value={value}
    />
  );
};

interface FormListItemListProps extends FormListItemProps {
  value: { [key: string]: string }[];
  onChange: (value: { [key: string]: Option['value'] }[]) => void;
  listItemFields: ListItemField[];
  renderer?: (keys: (string | number)[]) => string | React.ReactNode;
}

export const FormListItemList = ({
  fieldName,
  label,
  isEditing,
  onEditOpen,
  onEditClose,
  onChange,
  value = [],
  listItemFields,
  renderer
}: FormListItemListProps) => {
  const classes = useStyles();
  const initialState = listItemFields.reduce(
    (result, item) => ({ ...result, [item.fieldName]: '' }),
    {}
  );
  const [form, updateForm] = useState<{ [key: string]: string | number }>(
    initialState
  );

  const handleChange = (fieldName: string, value: string | number) => {
    updateForm({ ...form, [fieldName]: value });
  };

  const handleSave = () => {
    const result = [...value, form];
    onChange(result);
  };

  const handleRemove = (item: { [key: string]: string }) => {
    const keys = Object.keys(item);
    const result = value.filter(v => !keys.every(k => item[k] === v[k]));
    onChange(result);
  };

  const [firstKey, ...restKeys] = Object.keys(initialState);
  return (
    <>
      <ListItem>
        <ListItemText primary={label}></ListItemText>
        <ListItemSecondaryAction>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              if (isEditing) {
                onEditClose();
              } else {
                onEditOpen(fieldName);
              }
            }}
          >
            {isEditing ? 'Cancel' : 'Add'}
          </Button>
        </ListItemSecondaryAction>
      </ListItem>

      {isEditing && (
        <ListItem>
          {listItemFields.map(field => (
            <ListItemInput
              key={field.fieldName}
              field={field}
              value={form[fieldName]}
              onChange={handleChange}
            />
          ))}

          <ListItemSecondaryAction>
            <Tooltip title="Save">
              <div>
                <IconButton onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </div>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
      )}

      {value &&
        value.map(item => {
          return (
            <ListItem
              key={Object.values(item).join('-')}
              className={classes.nested}
            >
              {/* TODO clean this up */}
              <ListItemText
                primary={item[firstKey]}
                secondary={
                  renderer
                    ? renderer(restKeys.map(key => item[key]))
                    : restKeys.map(key => item[key]).join(', ')
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="Delete">
                  <div>
                    <IconButton onClick={() => handleRemove(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
    </>
  );
};

export default FormListItemList;
