import React, { useState } from 'react';

import { Form, Switch, InputList } from './FormComponents';
import { useConfigContext } from '../../context/config';

interface CalendarFormState {
  isEditing: boolean;
  editingField: string;
}

const initialConfig = {
  isActive: false,
  urls: []
};

const CalendarForm = () => {
  const [state, updateState] = useState<CalendarFormState>({
    isEditing: false,
    editingField: ''
  });
  const { config, updateConfig } = useConfigContext();
  const calendarConfig = (config && config.calendar) || initialConfig;

  const editField = (editingField: string) => {
    updateState({ ...state, isEditing: true, editingField });
  };

  const closeAllFields = () => {
    updateState({ ...state, isEditing: false, editingField: '' });
  };

  const handleToggle = (fieldName: string, value: boolean) => {
    updateConfig({ ...calendarConfig, [fieldName]: value }, 'calendar');
    closeAllFields();
  };

  const onUrlsUpdate = (urls: any[]) => {
    updateConfig({ ...calendarConfig, urls }, 'calendar');
    closeAllFields();
  };

  const { isActive, urls } = calendarConfig;
  const { editingField } = state;

  return (
    <Form>
      <Switch
        fieldName="isActive"
        label="Active"
        value={isActive}
        onToggle={handleToggle}
      />
      <InputList
        fieldName="urls"
        label="Calendar URLs (public iCal format)"
        emptyText="No calendars added."
        isEditing={editingField === 'urls'}
        onEditOpen={editField}
        onEditClose={closeAllFields}
        onChange={onUrlsUpdate}
        value={urls}
        listItemFields={[
          { fieldName: 'url', label: 'URL', style: { width: '85%' } }
        ]}
      />
    </Form>
  );
};

export default CalendarForm;
