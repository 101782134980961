import React, { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import GoogleMapsAutoComplete, { PlaceType } from '../GoogleMapsAutocomplete';
import useGooglePlaces, { PlaceDetail } from '../../hooks/useGooglePlaces';
import { useConfigContext } from '../../context/config';
import { Form, RadioGroup } from './FormComponents';
import { ScreenOrientation } from '../../types/config';
import { isFireTv } from '../../helpers';

interface FormState {
  isEditing: boolean;
  editingField: string;
}

const initialConfig = {
  address: undefined,
  lat: undefined,
  lng: undefined,
  place_id: undefined,
  orientation: undefined
};

const MainForm = () => {
  const [state, updateState] = useState<FormState>({
    isEditing: false,
    editingField: ''
  });
  const { getPlaceDetails } = useGooglePlaces();
  const { config, updateConfig } = useConfigContext();

  const mainConfig = (config && config.main) || initialConfig;

  const setAddress = async (result: PlaceType | string | null) => {
    if (!result || typeof result === 'string') {
      result && console.log('not setting result:', result);
      return;
    }

    const { place_id } = result;
    const place = await getPlaceDetails(place_id);
    const {
      formatted_address: address,
      geometry: { location }
    } = place as PlaceDetail;
    const lat = location.lat();
    const lng = location.lng();

    updateConfig({ ...mainConfig, address, place_id, lat, lng }, 'main');
  };

  const editField = (editingField: string) => {
    updateState({ ...state, isEditing: true, editingField });
  };

  const closeAllFields = () => {
    updateState({ ...state, isEditing: false, editingField: '' });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateConfig({ ...mainConfig, [e.target.name]: e.target.value }, 'main');
    closeAllFields();
  };

  const { address, lat, lng, orientation } = mainConfig;

  return (
    <>
      <GoogleMapsAutoComplete
        label={`${address ? 'Update' : 'Set'} your address`}
        onSelect={setAddress}
      />

      <Form>
        {address && (
          <>
            <ListItem>
              <ListItemText primary="Address" secondary={address} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Latitude" secondary={lat} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Longitude" secondary={lng} />
            </ListItem>
          </>
        )}

        <RadioGroup
          fieldName="orientation"
          label="Screen orientation"
          emptyText="You haven't specified a screen orientation."
          onEditOpen={editField}
          onEditClose={closeAllFields}
          onChange={handleChange}
          value={orientation}
          isEditing={state.editingField === 'orientation'}
          radioOptions={[
            { value: ScreenOrientation.Default, label: 'default' },
            { value: ScreenOrientation.Right, label: '90° right' },
            { value: ScreenOrientation.Left, label: '90° left' }
          ]}
        />

        {isFireTv && <ListItem>THIS IS A FIRE TV</ListItem>}
      </Form>
    </>
  );
};

export default MainForm;
