import React from 'react';
import moment from 'moment';

import { Event } from './ical';

const convertTimesToString = (start: moment.Moment, end: moment.Moment) => {
  let time = start.format('LT') + '-' + end.format('LT');
  time = time.replace(/(\s+|:00)/g, '');

  return time;
};

const EventList = ({ label, events }: { label: string; events: Event[] }) => {
  if (events.length === 0) return null;
  return (
    <>
      <h6>{label}</h6>
      <ul>
        {events.map(({ id, startDate, endDate, title, allDayEvent }: Event) => {
          return (
            <li key={id}>
              <i className="fa fa-calendar-o" />
              <span className="time">
                {allDayEvent
                  ? 'ALL DAY'
                  : convertTimesToString(startDate, endDate)}
              </span>
              {title || 'busy'}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default EventList;
