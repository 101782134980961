import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: '"Montserrat", Arial, sans-serif'
  },
  overrides: {
    MuiButton: {
      root: {
        '&:focus': {
          backgroundColor: 'rgba(0, 0, 100, 0.35)'
        },
        '&&:hover': {
          backgroundColor: 'rgba(0, 0, 100, 0.15)'
        }
      },
      label: {
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'rgba(0, 0, 100, 0.35)'
        },
        '&&:hover': {
          backgroundColor: 'rgba(0, 0, 100, 0.15)'
        }
      }
    }
  }
});

export default muiTheme;
