export const timeToLeaveString = (minutes: number): string =>
  `${minutes === 0 ? 'NOW' : `${minutes} mins`}`;

export interface LeaveTime {
  id: string; // unique id for React key
  msg: string; // "Leave in X minutes" string
  minToLeave: number; // num min to leave to arrive at stop or station
  delayed?: boolean; // is delayed from schedule
}

export const timesToLeaveTimes = (
  times: { minutes: number; delayed?: boolean; id: string }[],
  minToWalk: number // num min to walk to station or stop
): LeaveTime[] =>
  times.reduce((result, { minutes, delayed, id }) => {
    const minToLeave = minutes - minToWalk;
    if (minToLeave >= 0)
      result.push({
        id,
        msg: timeToLeaveString(minToLeave),
        minToLeave,
        delayed
      });
    return result;
  }, [] as LeaveTime[]);

export const isArrayBuffer = (obj: any) =>
  Object.prototype.toString.call(obj) === '[object ArrayBuffer]';
