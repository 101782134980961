import axios, { AxiosResponse } from 'axios';
import ical from 'ical';
import moment from 'moment';

const MAX_ENTRIES = 10;

export interface Event {
  id: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  title?: string;
  location?: string;
  description?: string;
  allDayEvent: boolean;
  isRecurring: boolean;
}

const logError = (e: Error) => console.log('Error fetching calendar:', e);

const fetchCalendars = async (urls: string[]) => {
  const results = await axios.all(
    urls.map(url =>
      axios(`/.netlify/functions/reverseProxy?url=${url}`).catch(logError)
    )
  );

  return results.filter(result => result) as AxiosResponse<any>[];
};

const limitFunction = (_: Date, i: any) => i < MAX_ENTRIES;

const isFBBirthday = (event: ical.CalendarComponent) =>
  event.uid && event.uid.includes('@facebook.com');

const isAllDayEvent = (event: any) => {
  if (event.start.length === 8 || isFBBirthday(event)) {
    return true;
  }

  var start = event.start || 0;
  var startDate = new Date(start);
  var end = event.end || 0;

  if (
    end - start === 24 * 60 * 60 * 1000 &&
    startDate.getHours() === 0 &&
    startDate.getMinutes() === 0
  ) {
    // Is 24 hours, and starts in the middle of the night.
    return true;
  }

  return false;
};

const shouldIncludeEvent = (
  event: Event,
  now: moment.Moment,
  future: moment.Moment
) => {
  const { allDayEvent, startDate, endDate } = event;
  if (
    (!allDayEvent && endDate < now) ||
    (allDayEvent && endDate <= now) ||
    startDate > future
  ) {
    return false;
  } else {
    return true;
  }
};

const parseEvent = (
  now: moment.Moment,
  future: moment.Moment,
  event: ical.CalendarComponent
): Event[] => {
  const nowDate = now.toDate();
  const futureDate = future.toDate();
  let recurringEvents: Event[] = [];

  if (event.type !== 'VEVENT' || !event.start || !event.end) {
    return [];
  }

  const startDate = moment(event.start);
  const endDate = moment(event.end);

  const id = event.uid || `${event.type}_${event.start}`;
  const title = event.summary || event.description;
  const description = event.description;
  const location = event.location;
  const allDayEvent = isAllDayEvent(event);

  // calculate the duration of the event for use with recurring events.
  var duration =
    parseInt(endDate.format('x')) - parseInt(startDate.format('x'));

  if (event.rrule && !isFBBirthday(event)) {
    const rule = event.rrule;
    const dates = rule.between(nowDate, futureDate, true, limitFunction);

    recurringEvents = dates.reduce((acc: Event[], date) => {
      const dateLookupKey = date.toISOString().substring(0, 10);

      // This date is an exception date, which means we should skip it in the recurrence pattern.
      const hasException = event.exdate && event.exdate[dateLookupKey];

      // Check if there is a recurrence override event.
      const overrideEvent =
        event.recurrences &&
        event.recurrences.find(
          r =>
            r.recurrenceid &&
            r.recurrenceid.toISOString().substring(0, 10) === dateLookupKey
        );

      const start = moment((overrideEvent && overrideEvent.start) || date);

      const durationOverride =
        overrideEvent &&
        parseInt(moment(overrideEvent.end).format('x')) -
          parseInt(start.format('x'));

      const end = start
        .clone()
        .add(durationOverride || duration, 'milliseconds');

      const recurringEvent = {
        id,
        title,
        startDate: start,
        endDate: end,
        allDayEvent,
        location,
        description,
        isRecurring: true
      };

      return !hasException && end > now && start < future
        ? [...acc, recurringEvent]
        : acc;
    }, []);
  }

  const parsedEvent = {
    id,
    title,
    location,
    description,
    allDayEvent,
    startDate,
    endDate,
    isRecurring: false
  };

  return [
    ...(shouldIncludeEvent(parsedEvent, now, future) ? [parsedEvent] : []),
    ...recurringEvents
  ];
};

export const getEvents = async (urls: string[]) => {
  const responses = await fetchCalendars(urls);

  const now = moment();
  // if >= 6PM, show events until noon tomorrow
  // else show until midnight
  const future =
    now.clone().hour() >= 18
      ? now
          .clone()
          .endOf('day')
          .add('12', 'hours')
      : now.clone().endOf('day');

  const events = responses
    .filter(({ data }) => data.startsWith('BEGIN'))
    .map(({ data }) => ical.parseICS(data))
    .reduce(
      (acc: ical.CalendarComponent[], d) => [...acc, ...Object.values(d)],
      []
    );

  return events
    .reduce(
      (acc: Event[], event) => [...acc, ...parseEvent(now, future, event)],
      []
    )
    .sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf());
};
