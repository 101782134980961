import axios, { AxiosTransformer } from 'axios';
import protobuf from 'protobufjs';

import { Complex, Line, MtaStation } from './types';
import { transit_realtime, NyctTripDescriptor } from '../../proto/compiled';
import { isArrayBuffer } from '../helpers';

// Thanks to https://github.com/ericandrewlewis/mta-realtime-subway-departures
const complexes = require('./data/complexes.json');
const stations = require('./data/stations.json');

function typedKeys<T>(o: T): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}

export const mtaLineToFeedMap: { [key: string]: string } = {
  '1': 'nyct%2Fgtfs',
  '2': 'nyct%2Fgtfs',
  '3': 'nyct%2Fgtfs',
  '4': 'nyct%2Fgtfs',
  '5': 'nyct%2Fgtfs',
  '6': 'nyct%2Fgtfs',
  S: 'nyct%2Fgtfs-ace',
  A: 'nyct%2Fgtfs-ace',
  C: 'nyct%2Fgtfs-ace',
  E: 'nyct%2Fgtfs-ace',
  N: 'nyct%2Fgtfs-nqrw',
  Q: 'nyct%2Fgtfs-nqrw',
  R: 'nyct%2Fgtfs-nqrw',
  W: 'nyct%2Fgtfs-nqrw',
  B: 'nyct%2Fgtfs-bdfm',
  D: 'nyct%2Fgtfs-bdfm',
  F: 'nyct%2Fgtfs-bdfm',
  M: 'nyct%2Fgtfs-bdfm',
  L: 'nyct%2Fgtfs-l',
  G: 'nyct%2Fgtfs-g',
  J: 'nyct%2Fgtfs-jz',
  Z: 'nyct%2Fgtfs-jz',
  '7': 'nyct%2Fgtfs-7',
  SIR: 'nyct%2Fgtfs-si'
};

const destinationLocationToComplexIdMap: { [key: string]: string } = {
  '145': '151',
  '148': '436',
  '168': '302',
  '177': '?',
  '179': '254',
  '205': '210',
  '207': '143',
  '238': '417',
  '241': '416',
  '242': '293',
  '962': '475',
  SFT: '23',
  FLA: '359',
  NLT: '352',
  WDL: '378',
  UTI: '345',
  DYR: '442',
  BWG: '414',
  BBR: '411',
  PEL: '360',
  GCS: '469',
  TSS: '468',
  '34H': '471',
  MST: '447',
  FAR: '209',
  LEF: '195',
  RPK: '203',
  EUC: '188',
  'P-A': '278',
  WTC: '171',
  BCR: '199',
  FKN: '139',
  DIT: '1',
  STL: '58',
  WHL: '23',
  '95S': '39',
  CTL: '261',
  BBC: '55',
  BPK: '68',
  KHY: '51',
  MET: '108',
  RPY: '138',
  '8AV': '115',
  CRS: '281',
  CHU: '243',
  BRD: '107'
};

const stopIdToComplexIdMap = stations.reduce(
  (acc: { [key: string]: string }, { stopId, complexId }: MtaStation) => ({
    ...acc,
    [stopId]: complexId
  }),
  {}
);

export const getComplexIdFromStopId = (stopId: string): string =>
  stopIdToComplexIdMap[stopId];

const stopIdToStationMap = stations.reduce(
  (acc: { [key: string]: MtaStation }, station: MtaStation) => ({
    ...acc,
    [station.stopId]: station
  }),
  {}
);

export const getStationFromStopId = (stopId: string): MtaStation =>
  stopIdToStationMap[stopId];

const getLinesFromComplexIds = (complexIds: string[]): string[] =>
  complexIds.reduce(
    (acc: string[], complexId) => [
      ...acc,
      ...complexes[complexId].daytimeRoutes
    ],
    []
  );

const fetchFeed = async (url: string) => {
  const response = await axios.get(url, {
    headers: { 'x-api-key': process.env.REACT_APP_NEW_MTA_API_KEY! },
    responseType: 'arraybuffer',
    transformResponse: [
      (data: any) => {
        if (data == null || !isArrayBuffer(data)) {
          return data;
        }

        try {
          const buf = protobuf.util.newBuffer(data);
          return transit_realtime.FeedMessage.decode(buf);
        } catch (err) {
          // TODO better error handling
          console.log('Error decoding protobuf', err);
          return err;
        }
      },
      ...(axios.defaults.transformRequest as AxiosTransformer[])
    ]
  });

  if (!response.data) throw new Error('No data');
  const data = JSON.parse(response.data) as transit_realtime.IFeedMessage;
  return data;
};

// Fetch the API feeds for the provided subway lines.
// Returns a Promise that resolves with the JSON data for all requests.
const fetchLineFeeds = async (lines: string[]) => {
  const feedUrls = [
    ...new Set(lines.map(line => `/mta/?feed=${mtaLineToFeedMap[line]}`))
  ];
  return Promise.all(feedUrls.map(feedUrl => fetchFeed(feedUrl)));
};

export const fetchDepartures = async (apiKey: string, complexIds: string[]) => {
  const lines = getLinesFromComplexIds(complexIds);

  return fetchLineFeeds(lines).then(
    (feeds: transit_realtime.IFeedMessage[]) => {
      const responses: Complex[] = [];

      complexIds.forEach(complexId => {
        const response: Complex = {
          complexId,
          name: complexes[complexId].name,
          lines: [],
          stopName: undefined,
          northDirectionLabel: undefined,
          southDirectionLabel: undefined
        };

        feeds.forEach(feed => {
          if (!feed.entity) return;

          addToResponseFromFeedMessages({
            feedMessages: feed.entity,
            complexId,
            response // TODO make these updates immutable
          });
        });

        response.lines.forEach(line => {
          typedKeys(line.departures).forEach(direction => {
            line.departures[direction] = line.departures[direction].sort(
              (a, b) => a.time - b.time
            );
          });
        });

        responses.push(response);
      });

      return responses;
    }
  );
};

// Provided a group of feed messages, extract departures
// that match the provided lines and stations.
const addToResponseFromFeedMessages = ({
  feedMessages,
  complexId,
  response
}: {
  feedMessages: transit_realtime.IFeedEntity[];
  complexId: string;
  response: Complex;
}) => {
  const nowInUnix = Math.floor(Date.now() / 1000);

  feedMessages.forEach(({ tripUpdate }) => {
    // Skip feedMessages that don't include a trip update.
    if (
      !tripUpdate ||
      !tripUpdate.stopTimeUpdate ||
      !tripUpdate.trip ||
      !tripUpdate.trip.routeId ||
      !tripUpdate.trip.tripId
    ) {
      return;
    }

    const {
      routeId,
      tripId,
      '.nyctTripDescriptor': descriptor
    } = tripUpdate.trip;
    const trainIdExploded = descriptor!.trainId!.split(' ');
    const destinationLocation = trainIdExploded[
      trainIdExploded.length - 1
    ].split('/')[1];
    const destinationStationId =
      destinationLocationToComplexIdMap[destinationLocation];
    const { direction } = descriptor!;
    if (!direction) return;

    tripUpdate.stopTimeUpdate.forEach(
      ({ departure, stopId: stopIdAndDirection }) => {
        if (!departure || !stopIdAndDirection) {
          return;
        }

        const { time } = departure;
        if (!time || time < nowInUnix) {
          return;
        }

        const stopId = stopIdAndDirection.substring(
          0,
          stopIdAndDirection.length - 1
        );
        const stopTimeComplexId = getComplexIdFromStopId(stopId);
        if (stopTimeComplexId !== complexId) {
          return;
        }
        const {
          line: lineName,
          stopName,
          northDirectionLabel,
          southDirectionLabel
        } = getStationFromStopId(stopId);
        let lineIndex = response.lines.findIndex(
          line => line.name === lineName
        );

        if (lineIndex === -1) {
          response.lines.push({
            name: lineName,
            departures: {
              NORTH: [],
              EAST: [],
              SOUTH: [],
              WEST: []
            }
          });
          lineIndex = response.lines.length - 1;
        }

        response.stopName = stopName;
        response.northDirectionLabel = northDirectionLabel;
        response.southDirectionLabel = southDirectionLabel;

        response.lines[lineIndex].departures[
          (direction as unknown) as 'NORTH' | 'EAST' | 'SOUTH' | 'WEST'
        ].push({
          tripId,
          routeId,
          time: Number(time),
          destinationStationId
        });
      }
    );
  });

  return response;
};
