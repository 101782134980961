import React from 'react';

import { MuniRoute, MuniStop } from './types';
import { timesToLeaveTimes } from '../helpers';

const Route = ({ stops, route }: { stops: MuniStop[]; route: MuniRoute }) => {
  const {
    routeTag,
    stopTag,
    direction: { title: directionTitle, prediction }
  } = route;
  const routeConfig = stops.find(
    stop => String(stop.route) === routeTag && String(stop.stopId) === stopTag
  );
  if (!routeConfig) return null;

  const { minToWalk } = routeConfig;
  const predictions = Array.isArray(prediction) ? prediction : [prediction];
  const times = predictions.map(({ minutes, delayed, tripTag }: any) => ({
    minutes,
    delayed,
    id: tripTag
  }));

  return (
    <div className="route">
      <div className="col">
        <span className="routeNumber">{routeTag}</span>
      </div>
      <div className="col routeDirection">
        {directionTitle.replace(/Outbound|Inbound/g, '')}
      </div>
      <div className="leaveTitle">Leave in</div>
      <div className="leaveTimes">
        {timesToLeaveTimes(times, minToWalk)
          .slice(0, 2)
          .map(({ id, msg }) => (
            <div key={id}>{msg}</div>
          ))}
      </div>
    </div>
  );
};

export default Route;
