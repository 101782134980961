import React, { useState } from 'react';
import { format } from 'date-fns';

import Widget from '../';

import './clock.scss';
import useInterval from '../../hooks/useInterval';

interface ClockProps {
  date: Date;
}

const Clock = () => {
  const [date, setDate] = useState<Date>(new Date());

  useInterval(() => {
    setDate(new Date());
  }, 1000);

  return (
    <Widget widgetName="clock">
      <div className="time">{format(date, 'h:mm')}</div>
      <div className="date">{format(date, 'cccc, LLLL do')}</div>
    </Widget>
  );
};

export default Clock;
