export enum BikeMarket {
  Citibike = 'citibike',
  Baywheels = 'baywheels',
  LyftLosAngeles = 'Lyft Scooters - Los Angeles'
}

export interface Station {
  station_name: string;
  station_id: string;
  num_bikes_available: number;
  num_ebikes_available: number;
  num_docks_available: number;
}

export interface Bike {
  bike_id: string;
  type: string;
  name: string;
  lon: number;
  lat: number;
  is_disabled: boolean;
  is_reserved: boolean;
}

export const marketSettings = {
  [BikeMarket.Citibike]: {
    hasStations: true,
    hasDocklessEbikes: false,
    hasScooters: false,
    hasEbikes: true,
    // TODO either surface this to the user or use it to populate
    stationInfoUrl:
      'https://gbfs.citibikenyc.com/gbfs/en/station_information.json'
  },
  [BikeMarket.Baywheels]: {
    hasStations: true,
    hasDocklessEbikes: true,
    hasScooters: false,
    hasEbikes: true,
    stationInfoUrl:
      'https://gbfs.baywheels.com/gbfs/en/station_information.json'
  },
  [BikeMarket.LyftLosAngeles]: {
    hasStations: false,
    hasDocklessEbikes: false,
    hasScooters: true,
    hasEbikes: true,
    stationInfoUrl: '/lyftlastmile?feed=lax/station_information.json'
  }
};
