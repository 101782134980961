import React from 'react';
import moment from 'moment';

import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { Form, Switch, InputList } from './FormComponents';
import { useConfigContext } from '../../context/config';

interface ScheduleFormState {
  isEditing: boolean;
  editingField: string;
}

const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const initialConfig = {
  isActive: false,
  events: []
};

const ScheduleForm = () => {
  const [state, updateState] = React.useState<ScheduleFormState>({
    isEditing: false,
    editingField: ''
  });
  const { config, updateConfig } = useConfigContext();

  const scheduleConfig = (config && config.schedule) || initialConfig;

  const editField = (editingField: string) => {
    updateState({ ...state, isEditing: true, editingField });
  };

  const closeAllFields = () => {
    updateState({ ...state, isEditing: false, editingField: '' });
  };

  const handleToggle = (fieldName: string, value: boolean) => {
    updateConfig({ ...scheduleConfig, [fieldName]: value }, 'schedule');
    closeAllFields();
  };

  const onEventsUpdate = (events: any[]) => {
    updateConfig({ ...scheduleConfig, events }, 'schedule');
    closeAllFields();
  };

  const { isActive, events } = scheduleConfig;
  const { editingField } = state;

  return (
    <Form>
      <Switch
        fieldName="isActive"
        label="Active"
        value={isActive}
        onToggle={handleToggle}
      />
      <InputList
        fieldName="events"
        label="Set your weekly schedule..."
        emptyText="No events added."
        isEditing={editingField === 'events'}
        onEditOpen={editField}
        onEditClose={closeAllFields}
        onChange={onEventsUpdate}
        value={events}
        listItemFields={[
          { fieldName: 'title', label: 'Title' },
          {
            fieldName: 'icon',
            label: 'Icon name',
            style: { width: '150px', flexGrow: 'unset' }
          },
          {
            fieldName: 'dayOfWeek',
            label: 'Day of week',
            options: [
              { label: 'Sunday', value: 0 },
              { label: 'Monday', value: 1 },
              { label: 'Tuesday', value: 2 },
              { label: 'Wednesday', value: 3 },
              { label: 'Thursday', value: 4 },
              { label: 'Friday', value: 5 },
              { label: 'Saturday', value: 6 }
            ],
            style: { width: '150px', flexGrow: 'unset' }
          },
          {
            fieldName: 'startHourOfDay',
            label: 'Start hour (0-24)',
            style: { width: '200px', flexGrow: 'unset' }
          },
          {
            fieldName: 'endHourOfDay',
            label: 'End hour (0-24)',
            style: { width: '200px', flexGrow: 'unset' }
          }
        ]}
        renderer={([icon, dayNumber, from, to]) => (
          <>
            <i className={`fa fa-${icon}`} /> on{' '}
            {DAYS_OF_WEEK[Number(dayNumber)]} from{' '}
            {moment()
              .hour(Number(from))
              .minute(0)
              .format('LT')}{' '}
            to{' '}
            {moment()
              .hour(Number(to))
              .minute(0)
              .format('LT')}
          </>
        )}
      />

      <ListItem>
        <Typography variant="caption">
          Find icon names{' '}
          <a
            href={`https://fontawesome.com/v4.7.0/icons/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </Typography>
      </ListItem>
    </Form>
  );
};

export default ScheduleForm;
