import React, { useRef, useState } from 'react';

import { makeStyles, MuiThemeProvider, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import muiTheme from './theme';

import Recaptcha from './components/recaptcha';
import { useAuthContext } from './context/auth';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(4),
    flexDirection: 'column'
  }
}));

const PhoneAuth = () => {
  const recaptcha = useRef(null);
  const [values, setValues] = useState({
    phone: '',
    code: ''
  });

  const {
    signedIn,
    signInWithPhoneNumber,
    setRecaptchaVerifier,
    sentMobileCode,
    confirmSignInWithMobilePhone,
    resetConfirmation
  } = useAuthContext();

  const handleChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  const phoneSignin = (e: any) => {
    e.preventDefault();
    signInWithPhoneNumber(`+1${values.phone}`);
  };

  const confirmCode = (e: any) => {
    e.preventDefault();
    confirmSignInWithMobilePhone(values.code);
  };

  const classes = useStyles();

  if (signedIn) {
    return null;
  }

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div
        style={{
          margin: 'auto',
          width: '75%'
        }}
      >
        <Grid container justify="space-between">
          <Grid
            item
            component={Card}
            className={classes.card}
            sm
            style={{ margin: 25, flexDirection: 'column' }}
          >
            <CardContent>
              <Typography variant="h2" align="center">
                mirror v3
              </Typography>
              {!sentMobileCode && (
                <form onSubmit={phoneSignin}>
                  <TextField
                    name="phone"
                    variant="outlined"
                    label="Phone number"
                    margin="normal"
                    onChange={handleChange}
                    value={values.phone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+1</InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                  <Grid justify="flex-end" item direction="row" container>
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </form>
              )}
              {sentMobileCode && (
                <form onSubmit={confirmCode}>
                  <Typography variant="body2" align="center">
                    Enter the code sent to your phone
                  </Typography>
                  <TextField
                    name="code"
                    variant="outlined"
                    label="Code"
                    margin="normal"
                    onChange={handleChange}
                    value={values.code}
                    fullWidth
                  />
                  <Grid justify="space-between" direction="row" container>
                    <Button variant="contained" onClick={resetConfirmation}>
                      Send again
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Grid>
                </form>
              )}
              <Recaptcha
                setter={setRecaptchaVerifier}
                callback={signInWithPhoneNumber}
              />
            </CardContent>
          </Grid>
        </Grid>
      </div>
    </MuiThemeProvider>
  );
};

export default PhoneAuth;
