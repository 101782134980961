import React from 'react';
import moment from 'moment';

import { useConfigContext } from '../../context/config';
import useInterval from '../../hooks/useInterval';
import { Event, getEvents } from './ical';

import EventList from './EventList';
import Widget from '..';

import './calendar.scss';

const DELAY = 300000 * 6; // 30 min

const isToday = (date: moment.Moment) => {
  const now = moment();
  return date.isSame(now, 'd');
};

const isYesterday = (date: moment.Moment) => {
  const yesterday = moment().subtract(1, 'day');
  return date.isSame(yesterday, 'd');
};

const Calendar = () => {
  const [state, updateState] = React.useState<{
    events: Event[];
  }>({ events: [] });
  const { config } = useConfigContext();

  const urls: string[] = (
    (config && config.calendar && config.calendar.urls) ||
    []
  ).map((u: { url: string }) => u.url);

  useInterval(() => {
    const getData = async () => {
      try {
        const events = await getEvents(urls);
        updateState({ events });
      } catch (e) {
        // nada
        console.log(e);
      }
    };
    getData();
  }, DELAY);

  const { events } = state;

  if (events.length === 0) {
    return null;
  }

  let yesterdayEvents: Event[] = [];
  let todayEvents: Event[] = [];
  let tomorrowEvents: Event[] = [];

  events.map(event => {
    if (isYesterday(event.startDate)) {
      yesterdayEvents.push(event);
    } else if (isToday(event.startDate)) {
      todayEvents.push(event);
    } else {
      tomorrowEvents.push(event);
    }
  });

  return (
    <Widget widgetName="calendar">
      <EventList label="YESTERDAY" events={yesterdayEvents} />
      <EventList label="TODAY" events={todayEvents} />
      <EventList label="TOMORROW" events={tomorrowEvents} />
    </Widget>
  );
};

export default Calendar;
