import React, {
  ChangeEvent,
  ReactNode,
  createRef,
  useState,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { useConfigContext } from '../../context/config';

import MainForm from './MainForm';
import BikeForm from './BikeForm';
import NextBusForm from './NextBusForm';
import MtaSubwayForm from './MtaSubwayForm';
import CalendarForm from './CalendarForm';
import ScheduleForm from './ScheduleForm';

const useStyles = makeStyles(() => ({
  dialogPaper: { height: '75%' },
  tabPanel: {
    padding: 15
  },
  dialogTitle: {
    padding: 0
  },
  dialogContent: {
    padding: '0 !important'
  },
  dialogActions: {
    justifyContent: 'space-between'
  }
}));

const TabPanel = ({
  show,
  children
}: {
  show: boolean;
  children?: ReactNode;
}) => {
  const classes = useStyles();
  if (!show) return null;

  return (
    <Grid container justify="space-between" className={classes.tabPanel}>
      {children}
    </Grid>
  );
};

const ConfigForm = ({
  onToggleMode,
  onLogout
}: {
  onToggleMode: () => void;
  onLogout: () => void;
}) => {
  const classes = useStyles();
  const { config } = useConfigContext();
  const [tabIndex, setTabIndex] = useState(0);
  let buttonRef = createRef<any>();

  const handleTabChange = (e: ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  useEffect(() => {
    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [buttonRef]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xl"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Tabs onChange={handleTabChange} value={tabIndex} variant="fullWidth">
          <Tab label="Settings" />
          <Tab label="Bikes" />
          <Tab label="NextBus" />
          <Tab label="MTA" />
          <Tab label="Calendar" />
          <Tab label="Schedule" />
        </Tabs>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }} dividers>
        <TabPanel show={tabIndex === 0}>
          <MainForm />
        </TabPanel>
        <TabPanel show={tabIndex === 1}>
          <BikeForm />
        </TabPanel>
        <TabPanel show={tabIndex === 2}>
          <NextBusForm />
        </TabPanel>
        <TabPanel show={tabIndex === 3}>
          <MtaSubwayForm />
        </TabPanel>
        <TabPanel show={tabIndex === 4}>
          <CalendarForm />
        </TabPanel>
        <TabPanel show={tabIndex === 5}>
          <ScheduleForm />
        </TabPanel>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          variant="contained"
          onClick={onToggleMode}
          buttonRef={buttonRef}
          disabled={!(config && config.main)}
          autoFocus
        >
          Switch to Mirror
        </Button>
        <Button variant="text" onClick={onLogout}>
          Log out
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigForm;
