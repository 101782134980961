import React, { useState } from 'react';
import axios from 'axios';
import cn from 'classnames';

import Widget from '../';
import ProgressBar from './ProgressBar';

import { Bike, Station, BikeMarket, marketSettings } from './types';
import { useConfigContext } from '../../context/config';
import useInterval from '../../hooks/useInterval';

import baywheelsLogo from './assets/baywheels-logo.svg';
import citibikeLogo from './assets/citibike-logo.svg';
import lyftLogo from './assets/lyft-logo.svg';
import './bikes.scss';

const makeGetObjectsInRadiusWorker = require('workerize-loader!./getObjectsInRadius'); // eslint-disable-line import/no-webpack-loader-syntax
const { getObjectsInRadius } = makeGetObjectsInRadiusWorker();

const LOGO_MAP = {
  [BikeMarket.Citibike]: citibikeLogo,
  [BikeMarket.Baywheels]: baywheelsLogo,
  [BikeMarket.LyftLosAngeles]: lyftLogo
};

const STATIONS_URL_MAP = {
  [BikeMarket.Citibike]:
    'https://gbfs.citibikenyc.com/gbfs/en/station_status.json',
  [BikeMarket.Baywheels]:
    'https://gbfs.baywheels.com/gbfs/en/station_status.json',
  [BikeMarket.LyftLosAngeles]: '/lyftlastmile?feed=lax/station_status.json'
};

const FREE_BIKES_URL_MAP = {
  [BikeMarket.Citibike]: undefined,
  [BikeMarket.Baywheels]:
    'https://gbfs.baywheels.com/gbfs/en/free_bike_status.json',
  [BikeMarket.LyftLosAngeles]: '/lyftlastmile?feed=lax/free_bike_status.json'
};

const DELAY = 60000; // 1 min

const parseStationData = (
  stationsToMonitor: { stationId: string; stationName: string }[],
  response: any
) => {
  if (!response.data || !response.data.data) return [];

  const { data } = response.data;
  const station_ids = stationsToMonitor.map(station => station.stationId);

  const stations = data.stations
    .filter(
      (station: any) =>
        station_ids.includes(station.station_id) && station.is_renting
    )
    .map((station: any) => {
      const stationConfig = stationsToMonitor.find(
        s => s.stationId === station.station_id
      );
      const station_name = (stationConfig && stationConfig.stationName) || '';
      return { ...station, station_name };
    });

  return stations;
};

const getNearestBikesAndScooters = async (
  latitude: number,
  longitude: number,
  radius: number,
  response: any
) => {
  if (!response.data || !response.data.data || !latitude || !longitude)
    return { electric_bikes: [], electric_scooters: [] };

  const { bikes }: { bikes: Bike[] } = response.data.data;
  const nearbyVehicles: Bike[] = await getObjectsInRadius(
    { latitude, longitude },
    radius,
    bikes
  );
  const electric_bikes = nearbyVehicles.filter(
    (bike: Bike) =>
      bike.type === 'electric_bike' && !bike.is_disabled && !bike.is_reserved
  );
  const electric_scooters = nearbyVehicles.filter(
    (bike: Bike) =>
      bike.type === 'electric_scooter' && !bike.is_disabled && !bike.is_reserved
  );

  return { electric_bikes, electric_scooters };
};

const StationStatus = ({
  station_name,
  num_bikes_available,
  num_ebikes_available,
  num_docks_available
}: Station) => {
  const total_bikes_available = num_bikes_available + num_ebikes_available;
  const primaryPercentage =
    (num_bikes_available / (total_bikes_available + num_docks_available)) * 100;
  const secondaryPercentage =
    (num_ebikes_available / (total_bikes_available + num_docks_available)) *
    100;
  const has_ebikes = Boolean(num_ebikes_available);

  return (
    <div className={cn('station', { ebikes: false })}>
      <div className="station-info">
        <div className="name">{station_name}</div>
        <div className="station-counts">
          {has_ebikes && (
            <div className="count">
              <div className="number">{num_ebikes_available}</div>
              <i className="fa fa-bolt" />
            </div>
          )}
          <div className="count">
            <div className="number">{num_bikes_available}</div>
            <i className="fa fa-bicycle" />
          </div>
        </div>
      </div>

      <ProgressBar
        primaryPercentage={primaryPercentage}
        secondaryPercentage={secondaryPercentage}
      />
    </div>
  );
};

interface BikesState {
  stations: Station[];
  electric_bikes: Bike[];
  electric_scooters: Bike[];
}

const Bikes = () => {
  const [state, updateState] = useState<BikesState>({
    stations: [],
    electric_bikes: [],
    electric_scooters: []
  });
  const { config } = useConfigContext();

  const lat = config && config.main && config.main.lat;
  const lng = config && config.main && config.main.lng;

  const market = config && config.bikes && config.bikes.market;
  const radius = config && config.bikes && config.bikes.radius;
  const stationsToMonitor = config && config.bikes && config.bikes.stations;

  const getStations = async () => {
    if (!market || !stationsToMonitor) return [];

    const URL = STATIONS_URL_MAP[market as BikeMarket];
    if (!URL) return [];

    const response = await axios.get(URL);
    return parseStationData(stationsToMonitor, response);
  };

  const getFreeBikesAndScooters = async () => {
    const emptyResponse = { electric_bikes: [], electric_scooters: [] };

    if (!lat || !lng || !market || !radius) return emptyResponse;

    const URL = FREE_BIKES_URL_MAP[market as BikeMarket];
    if (!URL) return emptyResponse;

    const response = await axios.get(URL);
    return getNearestBikesAndScooters(lat, lng, radius, response);
  };

  const getData = async () => {
    const stations = await getStations();
    const {
      electric_bikes,
      electric_scooters
    } = await getFreeBikesAndScooters();

    updateState({ ...state, stations, electric_bikes, electric_scooters });
  };

  useInterval(() => {
    getData();
  }, DELAY);

  const { stations, electric_bikes, electric_scooters } = state;
  const { hasDocklessEbikes, hasScooters } = marketSettings[
    market as BikeMarket
  ];

  if (!lat || !lng) {
    return (
      <Widget widgetName="bikes">
        Missing latitude and longitude configs.
      </Widget>
    );
  }

  return (
    <Widget widgetName="bikes" className={market}>
      <img className="logo" src={LOGO_MAP[market as BikeMarket]} />
      {hasDocklessEbikes && (
        <div className="station ebikes">
          <div className="station-info">
            <div className="name">
              <i className="fa fa-bolt" />
              Undocked ebikes nearby
            </div>
            <div className="number">{electric_bikes.length}</div>
          </div>
        </div>
      )}
      {hasScooters && (
        <div className="station ebikes">
          <div className="station-info">
            <div className="name">
              <i className="fa fa-bolt" />
              Scooters nearby
            </div>
            <div className="number">{electric_scooters.length}</div>
          </div>
        </div>
      )}
      {stations.map(station => (
        <StationStatus key={station.station_id} {...station} />
      ))}
    </Widget>
  );
};

export default Bikes;
