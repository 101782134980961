import React, { ReactNode } from 'react';
import cn from 'classnames';

import './widget.scss';

export enum WidgetName {
  Main = 'main',
  Bikes = 'bikes',
  NextBus = 'nextBus',
  MtaSubway = 'mtaSubway',
  Calendar = 'calendar',
  Schedule = 'schedule'
}

export interface WidgetConfig {
  // widgetName: WidgetName;
  [key: string]: any;
}

export interface WidgetProps {
  widgetName: string;
  logo?: React.ReactElement | string;
  label?: string;
  className?: string;
  children?: ReactNode;
}

const Widget = ({
  children,
  widgetName,
  logo,
  label,
  className
}: WidgetProps) => {
  return (
    <section className={cn('widget', `widget-${widgetName}`, className)}>
      {(logo || label) && (
        <div className="widget-head">
          {logo}
          {label && <h3>{label}</h3>}
        </div>
      )}
      {children}
    </section>
  );
};

export default Widget;
