import React, { useCallback, useState, useEffect } from 'react';
import { useKeyUp } from 'react-keyboard-input-hook';

import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';

import ConfigForm from './components/ConfigForm';
import PhoneAuth from './PhoneAuth';
import Mirror from './components/Mirror';

import './App.css';

import { useAuthContext } from './context/auth';
import { ConfigProvider, useConfigContext } from './context/config';
import useBodyClass from './hooks/useBodyClass';

import muiTheme from './theme';

const useStyles = makeStyles(() => ({
  menu: {
    position: 'absolute'
  }
}));

const App = () => {
  const { signedIn } = useAuthContext();

  if (!signedIn) {
    return <PhoneAuth />;
  }

  return (
    <ConfigProvider>
      <Main />
    </ConfigProvider>
  );
};

const Main = () => {
  const { signOut } = useAuthContext();
  const { config } = useConfigContext();
  const [isMirrorMode, toggleMode] = useState(false);

  const orientation = config && config.main && config.main.orientation;
  useBodyClass(`orientation-${orientation}`);

  return (
    <MuiThemeProvider theme={muiTheme}>
      {isMirrorMode ? (
        <Mirror onToggleMode={() => toggleMode(!isMirrorMode)} />
      ) : (
        <ConfigForm
          onToggleMode={() => toggleMode(!isMirrorMode)}
          onLogout={signOut}
        />
      )}
    </MuiThemeProvider>
  );
};

export default App;
