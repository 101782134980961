import { WidgetConfig, WidgetName } from '../widgets';

export enum ScreenOrientation {
  Default = 'default',
  Right = 'right',
  Left = 'left'
}

export interface UserObject {
  config: {
    [key in WidgetName]?: WidgetConfig;
  };
}
