import React, { useState, useCallback } from 'react';
import { useKeyUp } from 'react-keyboard-input-hook';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { isFireTv, If } from '../helpers';
import { useConfigContext } from '../context/config';
import useInterval from '../hooks/useInterval';

import Weather from '../widgets/Weather';
import Bikes from '../widgets/Bikes';
import Clock from '../widgets/Clock';
import NextBus from '../widgets/NextBus';
import MtaSubway from '../widgets/MtaSubway';
import Calendar from '../widgets/Calendar';
import Schedule from '../widgets/Schedule';
import AirQuality from '../widgets/AirQuality';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1)
  },
  paper: {
    width: '100%'
  },
  settingsButton: {
    '&:focus': {
      backgroundColor: 'gray'
    },
    '&&:hover': {
      backgroundColor: 'gray'
    }
  }
}));

const Mirror = ({ onToggleMode }: { onToggleMode: () => void }) => {
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { config } = useConfigContext();
  const menuOptions = [
    {
      label: 'Settings',
      icon: <SettingsIcon className={classes.icon} />,
      onSelect: onToggleMode
    },
    {
      label: 'Refresh',
      icon: <RefreshIcon className={classes.icon} />,
      onSelect: () => {
        window.location.reload();
      }
    },
    {
      label: 'Back',
      icon: <ArrowBackIcon className={classes.icon} />,
      onSelect: () => {
        setSelectedIndex(0);
        setShowMenu(false);
      }
    }
  ];

  const handleKeyUp = useCallback(
    ({ keyCode }) => {
      if (!isFireTv) return;

      const lastIndex = menuOptions.length - 1;

      if (!showMenu) {
        setShowMenu(true);
        return;
      }

      if (keyCode === 40) {
        // ArrowDown
        if (selectedIndex === lastIndex) {
          setSelectedIndex(0);
        } else {
          setSelectedIndex(selectedIndex + 1);
        }
      }
      if (keyCode === 38) {
        // ArrowUp
        if (selectedIndex === 0) {
          setSelectedIndex(lastIndex);
        } else {
          setSelectedIndex(selectedIndex - 1);
        }
      }
      if (keyCode === 13) {
        // Enter
        menuOptions[selectedIndex].onSelect();
        setShowMenu(false);
      }
      if (keyCode === 27) {
        // Esc
        setShowMenu(false);
      }
    },
    [selectedIndex, showMenu]
  );

  const { keyName, keyCode, keyCodeHistory, keyNameHistory } = useKeyUp(
    handleKeyUp
  );

  const lat = config?.main?.lat;
  const lng = config?.main?.lng;

  return (
    <>
      <div className="app">
        <div className="top top-left">
          <If condition={lat && lng}>
            <Weather>
              <AirQuality />
            </Weather>
          </If>
        </div>
        <div className="top top-right">
          <Clock />
          <If condition={config?.mtaSubway?.isActive}>
            <MtaSubway />
          </If>
          <If condition={true}>
            <Schedule />
          </If>
        </div>
        <div className="bottom bottom-left">
          <If condition={config?.nextBus?.isActive}>
            <NextBus />
          </If>
          <If condition={config?.bikes?.isActive}>
            <Bikes />
          </If>
        </div>
        <div className="bottom bottom-right">
          <If condition={config?.calendar?.isActive}>
            <Calendar />
          </If>
        </div>
      </div>
      {showMenu && (
        <div className="app-menu-wrapper">
          <div className="app-menu">
            <Paper elevation={3} classes={{ root: classes.paper }}>
              {menuOptions.map(({ label, icon, onSelect }, i) => (
                <ListItem
                  key={label}
                  selected={isFireTv && selectedIndex === i}
                  onClick={onSelect}
                  button
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <Typography>{label}</Typography>
                </ListItem>
              ))}
            </Paper>
          </div>
        </div>
      )}
      <div className="settings-button-wrapper">
        <Button
          variant="contained"
          classes={{ root: classes.settingsButton }}
          onClick={() => setShowMenu(!showMenu)}
        >
          Open settings menu
        </Button>
      </div>
    </>
  );
};

export default Mirror;
