import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

interface FormListItemSwitchProps {
  fieldName: string;
  label: string;
  value: boolean;
  onToggle: (fieldName: string, value: boolean) => void;
}

export const FormListItemSwitch = ({
  fieldName,
  label,
  value,
  onToggle
}: FormListItemSwitchProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(fieldName, e.target.checked);
  };

  return (
    <ListItem>
      <FormControlLabel
        control={
          <Switch checked={value} onChange={handleChange} name={fieldName} />
        }
        label={label}
      />
    </ListItem>
  );
};

export default FormListItemSwitch;
