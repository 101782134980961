import React from 'react';

interface ProgressBarProps {
  primaryPercentage: number;
  secondaryPercentage?: number;
}

const ProgressBar = ({
  primaryPercentage,
  secondaryPercentage
}: ProgressBarProps) => {
  return (
    <div className="progress-bar">
      <div
        className="progressPrimary"
        style={{ width: `${primaryPercentage}%` }}
      />
      {Boolean(secondaryPercentage) && (
        <div
          className="progressSecondary"
          style={{
            width: `${secondaryPercentage}%`,
            left: `${primaryPercentage}%`
          }}
        />
      )}
    </div>
  );
};

export default ProgressBar;
