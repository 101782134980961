import React, { createContext, useContext } from 'react';

import { firebase, auth, database } from '../firebase';

interface AuthContextShape {
  signedIn: boolean;
  sentMobileCode: boolean;

  signInWithPhoneNumber: (phoneNumber: string) => void;
  confirmSignInWithMobilePhone: (code: string) => void;
  setRecaptchaVerifier: React.Dispatch<
    React.SetStateAction<firebase.auth.ApplicationVerifier | undefined>
  >;
  resetPassword: (email: string) => void;
  resetConfirmation: () => void;
  signOut: () => void;

  readUserData: (path?: string) => Promise<unknown>;
  writeUserData: (data: any, path?: string) => Promise<unknown>;

  currentUser?: firebase.User;
}

export const AuthContext = createContext<AuthContextShape | undefined>(
  undefined
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [signedIn, setSignedIn] = React.useState<boolean>(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = React.useState<
    firebase.auth.ApplicationVerifier
  >();
  const [confirmation, setConfirmation] = React.useState<
    firebase.auth.ConfirmationResult
  >();

  React.useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(user => {
      setSignedIn(!!user);
    });
    return () => unregisterAuthObserver();
  }, []);

  const signOut = () => auth.signOut();

  const signInWithPhoneNumber = (phoneNumber: string) => {
    const appVerifier = recaptchaVerifier as firebase.auth.ApplicationVerifier;

    auth
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(confirmationResult => {
        console.log('confirmation result', confirmationResult);
        setConfirmation(confirmationResult);
      })
      .catch(error => {
        console.log('sign in error', error);
      });
  };

  const confirmSignInWithMobilePhone = (code: string) => {
    if (!confirmation) throw new Error('No confirmation result.');
    console.log('confirming sign in', code);
    confirmation.confirm(code);
  };

  const resetConfirmation = () => setConfirmation(undefined);

  const resetPassword = (email: string) => {
    const redirect = process.env.PUBLIC_URL
      ? { url: process.env.PUBLIC_URL }
      : null;
    auth.sendPasswordResetEmail(email, redirect);
  };

  const currentUser = auth && auth.currentUser;

  const readUserData = async (path?: string) => {
    const uId = auth.currentUser && auth.currentUser.uid;
    if (!uId) throw new Error('Not logged in.');

    const location = `users/${uId}${path ? `/${path}` : ''}`;
    return new Promise((resolve, reject) => {
      database
        .ref(location)
        .once('value')
        .then(snapshot => {
          resolve(snapshot.val());
        })
        .catch(err => reject(err));
    });
  };

  const writeUserData = async (data: any, path?: string) => {
    const uId = auth.currentUser && auth.currentUser.uid;

    if (!uId) throw new Error('Not logged in.');

    const location = `users/${uId}${path ? `/${path}` : ''}`;
    return new Promise((resolve, reject) => {
      database
        .ref(location)
        .set(data)
        .then(resolve)
        .catch(err => reject(err));
    });
  };

  const authContext = {
    signedIn,
    signInWithPhoneNumber,

    signOut,
    setRecaptchaVerifier,
    resetPassword,

    sentMobileCode: !!confirmation,
    resetConfirmation,
    confirmSignInWithMobilePhone,

    readUserData,
    writeUserData
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext) as AuthContextShape;
