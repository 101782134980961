import React, { useState } from 'react';
import axios, { AxiosPromise } from 'axios';

import Widget from '..';
import muniLogo from './muni-logo.svg';
import { MuniRoute } from './types';
import Route from './Route';

import './nextbus.scss';
import { useConfigContext } from '../../context/config';
import useInterval from '../../hooks/useInterval';
import { isLocalhost } from '../../helpers';

const NEXT_STOP_URI = isLocalhost()
  ? 'http://webservices.nextbus.com/service/publicJSONFeed?command=predictions&a='
  : '/nextbus?command=predictions&a=';
const DELAY = 15000;

const parseRoutes = (response: any): MuniRoute | null => {
  if (
    !response.data ||
    !response.data.predictions ||
    response.data.Error ||
    response.data.predictions.dirTitleBecauseNoPredictions
  )
    return null;
  const {
    predictions: { routeTag, routeTitle, stopTag, stopTitle, direction }
  } = response.data;

  return { routeTag, routeTitle, stopTag, stopTitle, direction };
};

interface NextBusState {
  routes: any[]; //  TODO MuniRoute[];
}

const NextBus = () => {
  const [state, updateState] = useState<NextBusState>({ routes: [] });
  const { config } = useConfigContext();

  const agency = config && config.nextBus && config.nextBus.agency;
  const stops = config && config.nextBus && config.nextBus.stops;

  useInterval(() => {
    const getNextBusTimes = async () => {
      if (!agency || !stops || stops.list === 0) return;

      const requests: AxiosPromise[] = stops.map(({ route, stopId }: any) =>
        axios.get(`${NEXT_STOP_URI}${agency}&r=${route}&s=${stopId}`)
      );
      const results = await axios.all(requests);
      const routes = results
        .map(response => parseRoutes(response))
        .filter(route => !!route);

      updateState({ ...state, routes });
    };

    getNextBusTimes();
  }, DELAY);

  const { routes } = state;
  const routesGroupedByStopId = routes.reduce((acc, route) => {
    (acc[route.stopTitle] = acc[route.stopTitle] || []).push(route);
    return acc;
  }, {});

  return (
    <Widget widgetName="muni">
      {Object.keys(routesGroupedByStopId).map(stopTitle => (
        <div key={stopTitle}>
          <h3>
            {agency === 'sf-muni' ? (
              <img className="muni-logo" src={muniLogo} />
            ) : (
              <i className="fa fa-bus" />
            )}
            {stopTitle}
          </h3>
          {routesGroupedByStopId[stopTitle].map((route: MuniRoute) => (
            <Route key={route.routeTitle} stops={stops} route={route} />
          ))}
        </div>
      ))}
    </Widget>
  );
};

export default NextBus;
