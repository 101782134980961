import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import InputList from './InputList';
import Checkbox from './Checkbox';
import RadioGroup from './RadioGroup';
import Switch from './Switch';
import TextField from './TextField';

const useStyles = makeStyles(() => ({
  list: {
    flexGrow: 1
  }
}));

const Form = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <List dense disablePadding classes={{ root: classes.list }}>
      {children}
    </List>
  );
};

export { Form, InputList, Checkbox, RadioGroup, Switch, TextField };

export interface Station {
  stationId: string;
  stationName: string;
}
