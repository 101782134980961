import React, { useState } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { FormListItemProps, Option } from './utils';

interface FormListItemRadioGroupProps extends FormListItemProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  radioOptions: Option[];
}

export const FormListItemRadioGroup = ({
  fieldName,
  label,
  emptyText,
  onEditOpen,
  onEditClose,
  onChange,
  value,
  isEditing,
  radioOptions
}: FormListItemRadioGroupProps) => (
  <ListItem>
    {isEditing && (
      <>
        <ListItemText
          disableTypography
          primary={<div>{label}</div>}
          secondary={
            <FormControl component="fieldset">
              <RadioGroup
                aria-label={label}
                name={fieldName}
                value={value}
                onChange={onChange}
                row
              >
                {radioOptions.map((option: Option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label || option.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          }
        />
      </>
    )}

    {!isEditing && (
      <ListItemText
        primary={label}
        secondary={Boolean(value) ? value : emptyText}
      />
    )}

    <ListItemSecondaryAction>
      {(Boolean(value) || isEditing) && (
        <Tooltip title={isEditing ? 'Cancel' : 'Change'}>
          <div>
            <IconButton
              onClick={() => {
                if (isEditing) {
                  onEditClose();
                } else {
                  onEditOpen(fieldName);
                }
              }}
            >
              {isEditing ? <CancelIcon /> : <EditIcon />}
            </IconButton>
          </div>
        </Tooltip>
      )}

      {!Boolean(value) && !isEditing && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => onEditOpen(fieldName)}
        >
          Add
        </Button>
      )}
    </ListItemSecondaryAction>
  </ListItem>
);

export default FormListItemRadioGroup;
