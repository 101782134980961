import React, { useState } from 'react';
import axios from 'axios';
import cn from 'classnames';

import Widget from '../';
import useInterval from '../../hooks/useInterval';
import { useConfigContext } from '../../context/config';

import './weather.scss';

const DELAY = 300000; // 5 min

interface WeatherState {
  feelsLike: number;
  icon: string;
  precipitation: number;
  summary: string;
  temp: number;
  tempMax: number;
  tempMin: number;
}

const parseWeatherData = (response: any): WeatherState | null => {
  if (!response.data) return null;

  const {
    currently: { icon, temperature, apparentTemperature },
    daily: {
      data: [{ temperatureMax, temperatureMin, precipProbability }]
    },
    hourly: { summary }
  } = response.data;

  const feelsLike = Math.round(apparentTemperature);
  const precipitation = Math.round(precipProbability * 100);
  const temp = Math.round(temperature);
  const tempMax = Math.round(temperatureMax);
  const tempMin = Math.round(temperatureMin);

  return { feelsLike, icon, precipitation, summary, temp, tempMax, tempMin };
};

const Weather = ({ children }: { children?: React.ReactNode }) => {
  const [weather, setWeather] = useState<WeatherState>();
  const { config } = useConfigContext();

  const lat = config?.main?.lat;
  const lng = config?.main?.lng;

  const getWeather = async () => {
    const response = await axios.get(
      `/darksky?key=${process.env.REACT_APP_DARK_SKY_API_KEY}&lat=${lat}&lng=${lng}`
    );
    const weather = parseWeatherData(response);
    if (weather) {
      setWeather(weather);
    }
  };

  useInterval(() => {
    getWeather();
  }, DELAY);

  if (!lat || !lng) {
    return (
      <Widget widgetName="weather">
        Enter your address in the settings menu.
      </Widget>
    );
  }
  if (!weather) return null;

  const {
    feelsLike,
    icon,
    precipitation,
    summary,
    temp,
    tempMax,
    tempMin
  } = weather;

  return (
    <Widget widgetName="weather">
      <div className="currently">
        <div className="temperature">{`${temp}°`}</div>
        <div className="icon-wrapper">
          <div className={cn('weather-icon', icon)} />
          <div className="high-low">
            <span className="low">{`${tempMin}°`}</span>&nbsp;&nbsp;
            <span className="high">{`${tempMax}°`}</span>
          </div>
        </div>
      </div>
      <div className="feelsLike">Feels like {feelsLike}°</div>
      <div className="summary">{summary}</div>
      {precipitation > 0 && (
        <div className="precipitation">
          <i className="fa fa-umbrella" /> {precipitation + '%'}
        </div>
      )}
      {children}
    </Widget>
  );
};

export default Weather;
