import React, { useState } from 'react';
import axios from 'axios';

import Widget from '../';

import useInterval from '../../hooks/useInterval';
import './airQuality.scss';
import { useConfigContext } from '../../context/config';

const DELAY = 60 * 60 * 1000; // 1 hour

const gradient: { position: number; rgb: number[] }[] = [
  { position: 0, rgb: [0, 228, 0] },
  { position: 0.1429, rgb: [255, 255, 0] },
  { position: 0.2987, rgb: [255, 126, 0] },
  { position: 0.4314, rgb: [255, 0, 0] },
  { position: 0.5743, rgb: [156, 28, 235] },
  { position: 1, rgb: [126, 0, 35] }
];

const pickHex = (color1: number[], color2: number[], weight: number) => {
  var p = weight;
  var w = p * 2 - 1;
  var w1 = (w / 1 + 1) / 2;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ];
  return rgb;
};

const getColorAndCategory = (aqi?: number) => {
  if (!aqi) {
    return { color: undefined, category: undefined, position: 0 };
  }
  const position = Math.min(aqi / 350, 1);

  const index = gradient.findIndex(g => position <= g.position);
  const colorRange = [index - 1, index];

  const first = gradient[colorRange[0]].rgb;
  const second = gradient[colorRange[1]].rgb;

  // Calculate ratio between the two closest colors
  const first_x = gradient[colorRange[0]].position;
  const second_x = gradient[colorRange[1]].position - first_x;
  const slider_x = position - first_x;
  const ratio = slider_x / second_x;

  const result = pickHex(second, first, ratio);
  const color = `rgb(${result.join()})`;

  if (aqi <= 50) {
    return { color, category: 'Good', position };
  } else if (aqi >= 51 && aqi <= 100) {
    return { color, category: 'Moderate', position };
  } else if (aqi >= 101 && aqi <= 150) {
    return {
      color,
      category: 'Unhealthy for Sensitive Groups',
      position
    };
  } else if (aqi >= 151 && aqi <= 200) {
    return { color, category: 'Unhealthy', position };
  } else if (aqi >= 201 && aqi <= 300) {
    return { color, category: 'Very Unhealthy', position };
  } else if (aqi >= 301) {
    return { color, category: 'Hazardous', position };
  } else {
    return { color: undefined, category: undefined, position: 0 };
  }
};

interface AirQualityState {
  aqi?: number;
  main?: string;
}

const AirQuality = () => {
  const { config } = useConfigContext();
  const [state, updateState] = useState<AirQualityState>({
    aqi: undefined,
    main: undefined
  });

  const lat = config?.main?.lat;
  const lng = config?.main?.lng;

  useInterval(() => {
    const getAQI = async () => {
      const response = await axios.get(
        `/airquality/?key=${process.env.REACT_APP_AIR_VISUAL_API_KEY}&lat=${lat}&lng=${lng}`
      );
      const { aqius, mainus } = response?.data?.data?.current?.pollution;

      updateState({ aqi: aqius, main: mainus });
    };

    getAQI();
  }, DELAY);

  const { aqi } = state;
  const { color, category, position } = getColorAndCategory(state.aqi);

  if (!aqi) {
    return null;
  }

  return (
    <Widget widgetName="air-quality">
      <i className="fa fa-smog" />
      AQI {aqi} - <b>{category}</b>
      <div className="air-quality-bar">
        <div className="bar"></div>
        <div
          className="knob"
          style={{
            backgroundColor: color,
            left: `calc(${position * 100}% - 5px)`
          }}
        />
      </div>
    </Widget>
  );
};

export default AirQuality;
