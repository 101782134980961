import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';

import { UserObject } from '../types/config';
import { useAuthContext } from './auth';

interface ConfigContextShape {
  config: UserObject['config'] | undefined;
  getConfig: any;
  updateConfig: any;
}

export const ConfigContext = createContext<ConfigContextShape | undefined>(
  undefined
);

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<UserObject['config'] | undefined>(
    undefined
  );
  const { readUserData, writeUserData } = useAuthContext();

  let firstLoad = false;
  useEffect(() => {
    if (!firstLoad) {
      firstLoad = true;
      getConfig();
    }
  }, []);

  const getConfig = async (path?: string) => {
    const location = `config${path ? `/${path}` : ''}`;
    const config = await readUserData(location);
    setConfig(config as UserObject['config']);
  };

  const updateConfig = async (data: any, path?: string) => {
    const location = `config${path ? `/${path}` : ''}`;

    // remove all undefined values
    Object.keys(data).forEach(
      key => data[key] === undefined && delete data[key]
    );

    await writeUserData(data, location);
    getConfig();
  };

  return (
    <ConfigContext.Provider value={{ config, getConfig, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () =>
  useContext(ConfigContext) as ConfigContextShape;
