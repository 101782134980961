import React, { useState } from 'react';

import { useConfigContext } from '../../context/config';
import { Form, InputList, Switch } from './FormComponents';

interface MtaSubwayFormState {
  isEditing: boolean;
  editingField: string;
}

const initialConfig = {
  isActive: false,
  stations: []
};

const MtaSubwayForm = () => {
  const [state, updateState] = useState<MtaSubwayFormState>({
    isEditing: false,
    editingField: ''
  });
  const { config, updateConfig } = useConfigContext();

  const mtaSubwayConfig = (config && config.mtaSubway) || initialConfig;

  const editField = (editingField: string) => {
    updateState({ ...state, isEditing: true, editingField });
  };

  const closeAllFields = () => {
    updateState({ ...state, isEditing: false, editingField: '' });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateConfig(
      { ...mtaSubwayConfig, [e.target.name]: e.target.value },
      'mtaSubway'
    );
    closeAllFields();
  };

  const handleToggle = (fieldName: string, value: boolean) => {
    updateConfig({ ...mtaSubwayConfig, [fieldName]: value }, 'mtaSubway');
    closeAllFields();
  };

  const onStationsUpdate = (stations: any[]) => {
    updateConfig({ ...mtaSubwayConfig, stations }, 'mtaSubway');
    closeAllFields();
  };

  const { isActive, stations } = mtaSubwayConfig;
  return (
    <Form>
      <Switch
        fieldName="isActive"
        label="Active"
        value={isActive}
        onToggle={handleToggle}
      />

      <InputList
        fieldName="stations"
        label="Stations"
        emptyText="No stations added."
        isEditing={state.editingField === 'stations'}
        onEditOpen={editField}
        onEditClose={closeAllFields}
        listItemFields={[
          {
            fieldName: 'complexId',
            label: 'Station id',
            style: { width: 200, marginRight: 5 }
          },
          {
            fieldName: 'minToWalk',
            label: 'Walking time to stop (in mins)'
          },
          { fieldName: 'direction', label: 'Direction (NORTH or SOUTH)' }
        ]}
        onChange={onStationsUpdate}
        value={stations}
      />
    </Form>
  );
};

export default MtaSubwayForm;
