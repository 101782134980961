import React, { useState } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';

import { FormListItemProps } from './utils';

interface FormListItemTextFieldProps extends FormListItemProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputType?: string;
  inputProps?: any; // TODO figure out correct type
}

export const FormListItemTextField = ({
  fieldName,
  label,
  emptyText,
  onEditOpen,
  onEditClose,
  onChange,
  value,
  isEditing,
  inputType,
  inputProps
}: FormListItemTextFieldProps) => {
  const [newValue, setNewValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<any>) => {
    const { key } = e;
    if (!key) return;

    if (key === 'Escape') {
      onEditClose();
    } else if (key === 'Enter') {
      onChange({
        target: { name: fieldName, value: newValue }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <ListItem>
      {isEditing && (
        <TextField
          type={inputType || 'text'}
          name={fieldName}
          margin="normal"
          label={label}
          helperText="Press 'enter' to save."
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          inputProps={inputProps}
          value={newValue}
        />
      )}

      {!isEditing && (
        <ListItemText
          primary={label}
          secondary={Boolean(value) ? value : emptyText}
        />
      )}

      <ListItemSecondaryAction>
        {(Boolean(value) || isEditing) && (
          <Tooltip title={isEditing ? 'Cancel' : 'Change'}>
            <div>
              <IconButton
                onClick={() => {
                  if (isEditing) {
                    onEditClose();
                  } else {
                    onEditOpen(fieldName);
                  }
                }}
              >
                {isEditing ? <CancelIcon /> : <EditIcon />}
              </IconButton>
            </div>
          </Tooltip>
        )}

        {!Boolean(value) && (
          <Button
            color="primary"
            disabled={isEditing}
            variant="contained"
            onClick={() => onEditOpen(fieldName)}
          >
            Add
          </Button>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FormListItemTextField;
